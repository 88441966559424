<template>
  <div>
    <md-tabs v-model="tabName">
      <md-tab-pane name="unit" label="单位信息">
        <div class="info">
          <md-field>
            <md-cell-item title="单位编号" :addon="collageinfo.collage_id" />
            <md-cell-item title="单位名称" :addon="collageinfo.collage_name" />
            <md-cell-item title="所属省份" :addon="collageinfo.province" />
            <!-- <md-cell-item title="协作组" addon="华南协作组" />
            <md-cell-item title="单位类型" addon="会长单位" />
            <md-cell-item title="单位管理员" addon="李四" /> -->
          </md-field>
        </div>
      </md-tab-pane>
      <md-tab-pane name="user" label="个人信息">
        <div class="info">
          <md-field>
            <md-cell-item title="姓名" :addon="userinfo.name" />
            <md-cell-item title="手机号" :addon="userinfo.mobile" />
            <md-cell-item title="微信号" :addon="userinfo.wechat" />
            <md-cell-item title="邮箱" :addon="userinfo.user_email" />
            <md-cell-item title="身份证" :addon="userinfo.idcard" />
            <md-cell-item title="用户ID" :addon="userinfo.id" />
          </md-field>
        </div>
      </md-tab-pane>
    </md-tabs>

    <md-action-bar :actions="actionBardata"></md-action-bar>
  </div>
</template>

<script>
import { Tabs, ActionBar, TabPane, Field, CellItem } from "mand-mobile";

export default {
  name: "UserInfo",
  components: {
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Field.name]: Field,
    [CellItem.name]: CellItem,
    [ActionBar.name]: ActionBar,
  },
  data() {
    return {
      tabName: "",
      collageinfo: {
        collage_id: '',
        collage_name: '',
      }
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userInfo
    },
    actionBardata() {
      if (this.tabName === "user") {
        return [
          {
            text: "返回",
            onClick: () => {
              this.goTo("/user")
            },
          },
          {
            text: "编辑",
            onClick: () => {
              this.goTo("/userinfo");
            },
          },
        ];
      } else {
        return [
          {
            text: "返回",
            onClick: () => {
              this.$router.back();
            },
          },
          {
            text: "邀请用户",
            onClick: () => {
              this.goTo("/inviteHome");
            },
          }
        ];
      }
    },
  },
  methods: {
    selectCollage() {
      this.$API.user.selectCollage().then(data => {
        this.collageinfo = data.data
      })
    },
  },
  created() {
    this.tabName = this.$route.params.tabName;
    this.selectCollage()
  },
};
</script>

<style lang="stylus" scoped>
.holder {
  display: block;
  width: 48px;
  height: 48px;
  background-color: #E6E6E6;
}

/deep/ .md-field {
  padding: 0;
  width: 100%;
}

/deep/ .md-cell-item-body {
  padding: 0;
}

.info {
  margin: 30px;
}
</style>
